<template>
  <svg
    version="1.0"
    xmlns="http://www.w3.org/2000/svg"
    width="3300.000000pt"
    height="1100.000000pt"
    viewBox="0 0 3300.000000 1100.000000"
    preserveAspectRatio="xMidYMid meet"
  >

    <g
      transform="translate(0.000000,1100.000000) scale(0.100000,-0.100000)"
      stroke="none"
    >
      <path
        d="M500 5500 l0 -5000 16000 0 16000 0 0 5000 0 5000 -16000 0 -16000 0
0 -5000z m31600 0 l0 -4600 -15600 0 -15600 0 0 4600 0 4600 15600 0 15600 0
0 -4600z"
      />
      <path
        d="M1500 5500 l0 -4000 8500 0 8500 0 0 4000 0 4000 -8500 0 -8500 0 0
-4000z m2320 1399 c255 -23 501 -101 681 -215 l38 -23 -48 -111 c-26 -60 -50
-113 -53 -116 -4 -3 -41 15 -85 39 -225 127 -470 185 -742 174 -172 -7 -268
-28 -386 -86 -183 -90 -279 -254 -261 -445 9 -100 46 -175 118 -240 109 -99
208 -138 633 -247 315 -81 414 -114 550 -180 120 -58 217 -135 278 -221 55
-77 72 -117 92 -213 81 -396 -164 -750 -596 -859 -289 -73 -675 -58 -965 39
-160 53 -337 149 -438 237 l-38 34 50 99 c28 55 55 106 60 113 7 10 30 -3 96
-52 253 -192 626 -291 963 -257 213 22 351 73 455 169 176 163 178 455 4 596
-119 97 -236 141 -647 246 -322 83 -467 132 -584 200 -145 86 -246 200 -293
333 -34 98 -42 279 -17 391 75 338 381 558 828 595 148 12 180 12 307 0z
m3340 -149 l0 -130 -795 0 -795 0 0 -485 0 -485 710 0 710 0 0 -125 0 -125
-710 0 -710 0 0 -510 0 -510 825 0 825 0 0 -125 0 -125 -975 0 -975 0 0 1368
c0 753 3 1372 7 1375 3 4 429 7 945 7 l938 0 0 -130z m1030 -1120 l0 -1250
770 0 770 0 0 -125 0 -125 -920 0 -920 0 0 1368 c0 753 3 1372 7 1375 3 4 71
7 150 7 l143 0 0 -1250z m2310 0 l0 -1250 770 0 770 0 0 -125 0 -125 -920 0
-920 0 0 1368 c0 753 3 1372 7 1375 3 4 71 7 150 7 l143 0 0 -1250z m3900
1120 l0 -130 -795 0 -795 0 0 -485 0 -485 710 0 710 0 0 -125 0 -125 -710 0
-710 0 0 -510 0 -510 825 0 825 0 0 -125 0 -125 -975 0 -975 0 0 1368 c0 753
3 1372 7 1375 3 4 429 7 945 7 l938 0 0 -130z m2060 106 c407 -76 678 -285
780 -603 69 -216 63 -481 -16 -679 -45 -113 -93 -185 -183 -275 -91 -90 -168
-142 -288 -195 -41 -17 -77 -34 -79 -37 -3 -3 133 -201 303 -439 170 -238 319
-448 332 -465 l22 -33 -163 0 -163 0 -310 440 c-301 428 -311 440 -339 435
-16 -3 -230 -5 -477 -5 l-449 0 0 -435 0 -435 -150 0 -150 0 0 1368 c0 753 3
1372 7 1376 4 4 282 6 618 4 529 -4 623 -7 705 -22z"
      />
      <path
        d="M15430 5936 l0 -686 403 0 c404 0 528 7 645 36 332 83 512 312 512
650 0 303 -159 534 -430 624 -148 50 -179 52 -667 57 l-463 5 0 -686z"
      />
      <path
        d="M19741 6882 l-19 -38 469 -654 c258 -360 467 -661 464 -668 -3 -7
-223 -314 -490 -682 -267 -367 -485 -673 -485 -679 0 -6 8 -26 17 -46 l17 -35
377 0 376 0 308 450 c169 248 311 452 315 455 4 2 148 -200 321 -450 l314
-454 381 0 380 -1 17 35 c9 20 17 40 17 45 0 6 -221 317 -492 692 -271 376
-492 689 -492 698 0 8 208 302 462 654 l461 638 -20 39 -19 39 -360 0 -359 0
-291 -420 c-161 -231 -295 -420 -299 -420 -3 1 -139 190 -301 420 l-295 419
-377 1 -378 0 -19 -38z"
      />
      <path
        d="M22822 6897 l-22 -23 0 -1375 0 -1376 23 -21 c23 -22 29 -22 338 -22
l315 0 27 28 27 28 0 352 0 352 257 -2 256 -3 260 -377 260 -378 357 0 356 0
17 35 c9 20 17 40 17 45 0 5 -126 189 -279 409 -154 221 -282 405 -285 410 -3
4 32 31 77 59 206 126 353 322 419 559 20 69 22 103 22 283 0 235 -12 298 -84
449 -141 295 -403 482 -787 563 -106 22 -124 23 -828 26 l-721 3 -22 -24z
m1386 -611 c140 -36 237 -111 290 -224 25 -52 27 -68 27 -182 0 -117 -2 -129
-29 -187 -31 -69 -107 -148 -172 -181 -97 -49 -140 -55 -476 -59 l-318 -5 0
432 0 432 308 -5 c237 -4 321 -8 370 -21z"
      />
      <path
        d="M26046 5536 l-617 -1385 23 -36 23 -35 343 3 343 2 121 293 122 292
602 0 602 0 122 -295 122 -295 349 0 348 0 25 36 24 36 -26 56 c-14 32 -292
653 -618 1382 l-592 1325 -349 3 -349 2 -618 -1384z m1143 145 c94 -227 171
-418 171 -422 0 -5 -160 -9 -355 -9 -195 0 -355 3 -355 6 0 20 355 853 361
847 4 -4 84 -194 178 -422z"
      />
      <path
        d="M28373 6884 l-20 -36 528 -879 529 -879 0 -484 0 -483 23 -21 c23
-22 28 -22 343 -22 l321 0 21 23 c22 23 22 24 22 503 l0 480 28 45 c15 24 254
420 530 880 l503 836 -22 37 -21 36 -327 0 -326 0 -350 -580 c-192 -319 -352
-580 -355 -580 -3 1 -160 260 -350 578 l-346 577 -355 3 -355 2 -21 -36z"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: 'AppLogoWithText',
}
</script>

<style scoped>

</style>
